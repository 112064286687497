export const GAME_TITLE = 'كلمات'

export const WIN_MESSAGES = ['أحسنت صنعاً']
export const GAME_COPIED_MESSAGE = 'نُسخت النتيجة إلى الحافظة'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'يجب أن تكون الكلمة من 5 حروف'
export const WORD_NOT_FOUND_MESSAGE = 'هذا التخمين غير موجود في قاموس اللعبة'
export const HARD_MODE_ALERT_MESSAGE =
  'يمكنك تفعيل المستوى الصعب في بداية اللعبة فقط'
export const HARD_MODE_DESCRIPTION = ''
export const HIGH_CONTRAST_MODE_DESCRIPTION = ''
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `الكلمة الصحيحة هي ${solution}`

export const ENTER_TEXT = '↵'
export const DELETE_TEXT = 'مسح'
export const STATISTICS_TITLE = 'إحصائيات'
export const GUESS_DISTRIBUTION_TEXT = 'توزيع التخمينات'
export const NEW_WORD_TEXT = 'الوِرد القادم كلمة متاح في'
export const SHARE_TEXT = 'شارك'
export const TOTAL_TRIES_TEXT = 'عدد المحاولات'
export const SUCCESS_RATE_TEXT = 'نسبة النجاح'
export const CURRENT_STREAK_TEXT = 'تتالي مرات الربح الحالية'
export const BEST_STREAK_TEXT = 'أفضل تتالي ربح'
export const DISCOURAGE_INAPP_BROWSER_TEXT = ""
  