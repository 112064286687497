import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="طريقة اللعب" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      خمِّن كلمة  في  6  محاولات. سيتغيّر لون المربعات بعد كل محاولة لإظهار مدى قرب تخمينك من الكلمة.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ا"
          status="correct"
        />
        <Cell value="ب" />
        <Cell value="ت" />
        <Cell value="س" />
        <Cell value="م" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      حرف الألف موجود في الكلمة في المكان الصحيح.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ي" />
        <Cell value="ت" />
        <Cell value="ح" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="د"
          status="present"
        />
       
        <Cell value="ث" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      حرف الدال موجود في الكلمة ولكن في المكان الخطأ.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ج" />
        <Cell value="س" />
        <Cell value="ي" />
        <Cell isRevealing={true} isCompleted={true} value="م" status="absent" />
        <Cell value="ة" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      حرف الميم غير موجود في الكلمة في أي مكان.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
      اللعبة مع اللغة العربية باستخدام هذه النسخة مفتوحة المصدر: {' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
           react-wordle
        </a>{' '}
      </p>
    </BaseModal>
  )
}
